import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import FaqAccordion from "../Global/FaqAccordion";
import { useTranslation } from "react-i18next";

const FaqContent = ({ section }) => {
  const { t } = useTranslation();

  return (
    <section className="faqContent">
      <Container>
        <Row>
          <Col>
            <h1 className="fw-700 font-bukra text-black title-lg">
              {t("Frequently asked questions")}
            </h1>
            <FaqAccordion data={section} />
            <div className="mt-3 mt-md-4">
              <Link
                to="/faqs"
                className="primary-color text-decoration-none fw-500 link me-4"
              >
                {t("View all FAQs")}
              </Link>
              <Link
                to="/contact"
                className="primary-color text-decoration-none fw-500 link"
              >
                {t("Contact us")}
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default FaqContent;
