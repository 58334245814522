import { Col, Container, Row } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import OfferCard from "../Components/Global/OfferCard";
import Image from "react-bootstrap/Image";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { selectLangState } from "../redux/configurationSlice";
import { useSelector } from "react-redux";

import { Navigation, Pagination, EffectFade } from "swiper/modules";
import { HomeAPI } from "../apis/homeApi";
import { Fragment, useEffect, useState } from "react";
import PageLoader from "../Components/loaders/pageLoader";
import BrandCard from "../Components/Global/BrandCard";

const Brands = () => {
  const { name } = useParams();
  const { lang } = useSelector(selectLangState);

  const { t } = useTranslation();
  const { data, isLoading, refetch, status } = useQuery({
    queryKey: [
      "section/items",
      {
        company: "ESD",
        location_id: 0,
        language: lang?.startsWith("ar") ? "ar" : "en",
        platform: "website",
        section_identifier: name,
      },
    ],
    queryFn: ({ queryKey }) => HomeAPI.getSectionItems({ ...queryKey[1] }),
  });

  if (status === "pending") return <PageLoader />;

  return (
    <>
      <div className="contentHolder">
        <Container>
          <h1 className="title-lg page-head primary-color fw-700 font-bukra">
            {data?.sections?.[0]?.title}
          </h1>
          <Row>
            {data?.sections?.[0]?.section_items?.length ? (
              data?.sections?.[0]?.section_items?.map((section, index) => (
                <Fragment key={index}>
                  <Col xs={12} md={6} xl={3} className="mb-4">
                    <BrandCard section={section} />
                  </Col>
                </Fragment>
              ))
            ) : (
              <NoDataComponent />
            )}
          </Row>
        </Container>
      </div>
    </>
  );
};

const NoDataComponent = () => {
  const { t } = useTranslation();
  return (
    <div
      id="error-page"
      style={{ height: "60vh" }}
      className=" d-flex flex-column align-items-center justify-content-center"
    >
      <div className="row p-2 m-2  text-black  rounded-2">
        <h1>{t("Oops")}!</h1>
      </div>
      <div className="row pt-3 mt-2 mb-3 bg-warning shadow rounded-2">
        <p>
          <i>{t("No Data found!")}</i>
        </p>
      </div>
    </div>
  );
};

export default Brands;
