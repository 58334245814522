import { Col, Container, Row } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import Image from "react-bootstrap/Image";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectLangState } from "../../redux/configurationSlice";
import { Link } from "react-router-dom";
import { deepLinkExist, parseDeepLink } from "../../utils/utilityFunctions";

const FeaturedBrandsHome = ({ section }) => {
  const { lang } = useSelector(selectLangState);
  const { t } = useTranslation();

  return (
    <section className="offer-section fBrands">
      <Container>
        <Row className="head g-0">
          <Col xs={7}>
            <h2 className="fw-700 font-bukra text-black title-lg">
              {section?.title}
            </h2>
          </Col>
          {section?.show_all && (
            <Col xs={5} className="d-flex justify-content-end">
              <div>
                <Link
                  to={`/brands/${section?.section_identifier}`}
                  className="primary-color text-decoration-none fw-500 link btn btn-outline"
                >
                  {t("Show All")}
                </Link>
              </div>
            </Col>
          )}
          <Col xs={12} className="d-none d-md-block">
            <small className="fw-300 text-black text-info">
              {t(
                "Our brands feature the best offers, discounts and deals in the market."
              )}
            </small>
          </Col>
        </Row>
        <Row xs={1} className="g-0">
          <Col>
            <Swiper
              dir={lang?.startsWith("ar") ? "rtl" : "ltr"}
              key={lang?.startsWith("ar") ? "rtl" : "ltr"}
              navigation={{
                prevEl: ".swiper-brand-prev",
                nextEl: ".swiper-brand-next",
              }}
              modules={[Navigation]}
              className="brand-swiper"
              breakpoints={{
                320: {
                  slidesPerView: 3,
                  spaceBetween: 12,
                },
                768: {
                  slidesPerView: 5,
                  spaceBetween: 16,
                },
                1200: {
                  slidesPerView: 6,
                  spaceBetween: 20,
                },
              }}
            >
              {section?.section_items?.map((brand, key) => (
                <SwiperSlide key={key}>
                  {deepLinkExist(brand?.deeplink) ? (
                    <Link
                      className="d-block"
                      to={parseDeepLink(brand?.deeplink)}
                    >
                      <Image
                        src={brand?.image_url_web}
                        alt="merchant logo"
                        className="w-100"
                      />
                    </Link>
                  ) : (
                    <Image
                      src={brand?.image_url_web}
                      alt="merchant logo"
                      className="w-100"
                    />
                  )}
                </SwiperSlide>
              ))}
              <div className="swiper-button-prev swiper-brand-prev"></div>
              <div className="swiper-button-next swiper-brand-next"></div>
            </Swiper>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default FeaturedBrandsHome;
