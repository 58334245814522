import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import { Col, Container, Row } from "react-bootstrap";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, EffectFade } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import { useTranslation } from "react-i18next";
import { selectLangState } from "../../redux/configurationSlice";
import { useSelector } from "react-redux";
import { deepLinkExist, parseDeepLink } from "../../utils/utilityFunctions";

const MainHeroMember = ({ banners, section }) => {
  const { lang } = useSelector(selectLangState);

  const { t } = useTranslation();

  return (
    <section className="heroMain member">
      <Swiper
        key={lang?.startsWith("ar") ? "rtl" : "ltr"}
        dir={lang?.startsWith("ar") ? "rtl" : "ltr"}
        navigation={{
          prevEl: ".mainHeroSwiper-button-prev",
          nextEl: ".mainHeroSwiper-button-next",
        }}
        modules={[Navigation, Pagination, EffectFade]}
        pagination={{ clickable: true }}
        // effect="fade"
        // fadeEffect={{ crossFade: true }}
        className="mainHero-swiper"
        breakpoints={{
          320: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
        }}
        // noSwipingSelector=".swiper-slide"
      >
        {banners?.map((banner, key) => (
          <SwiperSlide key={key}>
            {deepLinkExist(banner?.deeplink) ? (
              <div className="imgHolder">
                <Link className="d-block" to={parseDeepLink(banner?.deeplink)}>
                  <Image
                    src={
                      banner?.image_url_web ?? "/images/homeMember-default.png"
                    }
                    // src="/images/home-member-dummyBanner.png"
                    alt="Esaad mobileApp"
                    className="d-block img-fluid w-100"
                  />
                </Link>
              </div>
            ) : (
              <div className="imgHolder">
                <Image
                  src={
                    banner?.image_url_web ?? "/images/homeMember-default.png"
                  }
                  // src="/images/home-member-dummyBanner.png"
                  alt="Esaad mobileApp"
                  className="d-block img-fluid w-100"
                />
              </div>
            )}
          </SwiperSlide>
        ))}

        {/* <div className="swiper-button-prev text-black">Back</div>
                <div className="swiper-button-next bg-primary text-white">Next</div> */}
      </Swiper>
    </section>
  );
};

export default MainHeroMember;
