import OtpVerification from "../Components/auth/otpVerification";
import AboutUs from "../Pages/AboutUs";
import Brands from "../Pages/Brands";
import Categories from "../Pages/Categories";
import CategoryListing from "../Pages/CategoryListing";
import ContactUs from "../Pages/ContactUs";
import FaqPage from "../Pages/FaqPage";
import HomeGuest from "../Pages/HomeGuest";
import HomeMember from "../Pages/HomeMember";
import HowItWorks from "../Pages/HowItWorks";
import MerchantDetail from "../Pages/MerchantDetail";
import MyAccount from "../Pages/MyAccount";
import News from "../Pages/News";
import NewsDetail from "../Pages/NewsDetail";
import Offers from "../Pages/Offers";
import PartnersFacilities from "../Pages/PartnersFacilities";
import PartnersFacilitiesMobile from "../Pages/PartnersFacilitiesMobile";
import PrivacyPolicy from "../Pages/PrivacyPolicy";
import Recaptcha from "../Pages/Recaptcha";
import RegisterCompany from "../Pages/RegisterCompany";
import SearchListing from "../Pages/SearchListing";
import StoryDetail from "../Pages/StoryDetail";
import SuccessStories from "../Pages/SuccessStories";
import TermsAndCondition from "../Pages/TermsAndCondition";
import Login from "../Pages/auth/Login";
import SetupPassword from "../Pages/auth/SetupPassword";
import VerifyEmail from "../Pages/auth/VerifyEMail";
import ActivationFlow from "../Pages/auth/activationFlow";
import ForgetFlow from "../Pages/auth/forgetFlow";

export const sharedRoutes = [
  {
    path: "/about",
    element: AboutUs,
    isPublicRoute: true,
    isHeaderFooter: true,
  },
  {
    path: "/media-center/news",
    element: News,
    isPublicRoute: true,
    isHeaderFooter: true,
  },
  {
    path: "/media-center/success-stories",
    element: SuccessStories,
    isPublicRoute: true,
    isHeaderFooter: true,
  },
  {
    path: "/partner-facilities",
    element: PartnersFacilities,
    isPublicRoute: true,
    isHeaderFooter: true,
  },
  {
    path: "/partner",
    element: PartnersFacilitiesMobile,
    isPublicRoute: true,
    isHeaderFooter: false,
  },
  {
    path: "/contact",
    element: ContactUs,
    isPublicRoute: true,
    isHeaderFooter: true,
  },
  {
    path: "/register-company",
    element: RegisterCompany,
    isPublicRoute: true,
    isHeaderFooter: true,
  },
  {
    path: "/terms-and-condition",
    element: TermsAndCondition,
    isPublicRoute: true,
    isHeaderFooter: true,
  },
  {
    path: "/privacy-policy",
    element: PrivacyPolicy,
    isPublicRoute: true,
    isHeaderFooter: true,
  },
  {
    path: "/how-it-works/:lang",
    element: HowItWorks,
    isPublicRoute: true,
    isHeaderFooter: false,
  },
  {
    path: "/faqs",
    element: FaqPage,
    isPublicRoute: true,
    isHeaderFooter: true,
  },

  { path: "/media-center/news/:id", element: NewsDetail, isHeaderFooter: true },
  {
    path: "/media-center/success-stories/:id",
    element: StoryDetail,
    isHeaderFooter: true,
  },
];

export const authRoutes = [
  {
    path: "/login",
    element: Login,
    isAuthRoute: true,
    isPublicRoute: true,
    isHeaderFooter: true,
  },
  {
    path: "/verify",
    element: Recaptcha,
    isAuthRoute: true,
    isPublicRoute: true,
    isHeaderFooter: true,
  },
  {
    path: "/verify/email/:token",
    element: VerifyEmail,
    isAuthRoute: true,
    isPublicRoute: true,
    isHeaderFooter: true,
  },
  {
    path: "/activate-membership",
    element: ActivationFlow,
    isAuthRoute: true,
    isPublicRoute: true,
    isHeaderFooter: true,
  },
  {
    path: "/forgot-password",
    element: ForgetFlow,
    isAuthRoute: true,
    isPublicRoute: true,
    isHeaderFooter: true,
  },
  {
    path: "/otp-verification",
    element: OtpVerification,
    isAuthRoute: true,
    isPublicRoute: true,
    isHeaderFooter: true,
  },
  {
    path: "/setup-password",
    element: SetupPassword,
    isAuthRoute: true,
    isPublicRoute: true,
    isHeaderFooter: true,
  },
];

export const publicRoutes = [
  {
    path: "/home",
    element: HomeGuest,
    isPublicRoute: true,
    isHeaderFooter: true,
  },
  ...authRoutes,
  ...sharedRoutes,
];

export const privateRoutes = [
  { path: "/home-member", element: HomeMember, isHeaderFooter: true },
  { path: "/categories", element: Categories, isHeaderFooter: true },
  { path: "/offers/:name", element: Offers, isHeaderFooter: true },
  { path: "/brands/:name", element: Brands, isHeaderFooter: true },
  { path: "/categories/:name", element: CategoryListing, isHeaderFooter: true },
  { path: "/search/:text", element: SearchListing, isHeaderFooter: true },
  { path: "/my-account", element: MyAccount, isHeaderFooter: true },
  {
    path: "/merchant-detail/:id",
    element: MerchantDetail,
    isHeaderFooter: true,
  },
];
