import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import { deepLinkExist, parseDeepLink } from "../../utils/utilityFunctions";

const BrandCard = ({ section }) => {
  return (
    <Card className="offer">
      {deepLinkExist(section?.deeplink) ? (
        <Link to={parseDeepLink(section?.deeplink)}>
          <div className="imgHolder">
            <Card.Img
              variant="top"
              src={section?.image_url_web || "/images/placeholderImage.png"}
              alt="Offer Image"
              className="d-block img-fluid w-100"
            />
          </div>
        </Link>
      ) : (
        <div className="imgHolder">
          <Card.Img
            variant="top"
            src={section?.image_url_web || "/images/placeholderImage.png"}
            alt="Offer Image"
            className="d-block img-fluid w-100"
          />
        </div>
      )}
    </Card>
  );
};

export default BrandCard;
