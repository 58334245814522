import MainHeroMember from "../Components/Home/MainHeroMember";
import HomeNews from "../Components/Home/HomeNews";
import FaqContent from "../Components/Home/FaqContent";
import AppDownload from "../Components/Global/AppDownload";
import FilterBar from "../Components/Home/FilterBar";
import MainCategories from "../Components/Home/MainCategories";
import FeaturedOffers from "../Components/Home/FeaturedOffers";
import NewOffers from "../Components/Home/NewOffers";
import NearbyOffers from "../Components/Home/NearbyOffers";
import FeaturedBrands from "../Components/Home/FeaturedBrands";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { selectLangState } from "../redux/configurationSlice";
import { useSelector } from "react-redux";
import { HomeAPI } from "../apis/homeApi";
import { useEffect, useState } from "react";
import { MoengageService } from "../services/moengage";
import { selectUserDetails } from "../redux/authSlice";
import FeaturedBrandsHome from "../Components/Home/FeaturedBrandsHome";
import PageLoader from "../Components/loaders/pageLoader";

const Section = ({ section, refetch }) => {
  const { t } = useTranslation();
  if (section?.section_identifier === "banners_section") {
    return (
      <MainHeroMember banners={section?.section_items} section={section} />
    );
  }
  if (section?.section_identifier === "categories_section") {
    return (
      <MainCategories categories={section?.section_items} section={section} />
    );
  }
  if (section?.section_identifier === "nearby_offers") {
    return <NearbyOffers refetch={refetch} section={section} />;
  }
  if (section?.section_identifier === "new_offers") {
    return <NewOffers refetch={refetch} section={section} />;
  }
  if (section?.section_identifier === "featured_offers") {
    return <FeaturedOffers refetch={refetch} section={section} />;
  }
  if (section?.section_identifier === "featured_brands") {
    return <FeaturedBrandsHome section={section} />;
  }
  if (section?.section_identifier === "news_section") {
    return (
      <HomeNews
        news={section?.section_items}
        title={`${t("News")}`}
        section={section}
      />
    );
  }
  if (section?.section_identifier === "faq_section") {
    return <FaqContent section={section} />;
  }
};

const HomeMember = () => {
  const { t } = useTranslation();
  const { lang } = useSelector(selectLangState);
  const [location, setLocation] = useState({ lat: null, lng: null });
  const user = useSelector(selectUserDetails);

  const { data, refetch, isLoading } = useQuery({
    queryKey: [
      "home",
      {
        company: "ESD",
        location_id: 0,
        language: lang?.startsWith("ar") ? "ar" : "en",
        platform: "website",
        lat: location?.lat,
        lng: location?.lng,
      },
    ],
    queryFn: ({ queryKey }) => HomeAPI.getHomeSections({ ...queryKey[1] }),
  });

  useEffect(() => {
    // Request the user's location
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLocation({ lat: latitude, lng: longitude });
        },
        (error) => {
          console.error("Error getting location: ", error);
        }
      );
    }
  }, []);

  useEffect(() => {
    MoengageService.visitHomePage();
    MoengageService.homeScreenView({
      app_language: "",
      user_type: "guest",
      "Account Status": "Account Status",
    });
  }, []);

  useEffect(() => {
    MoengageService.homeScreenView({
      app_language: lang?.startsWith("ar") ? "ar" : "en",
      user_type: user?.user_id,
      "Account Status": user?.status,
    });
  }, [user]);

  return isLoading ? (
    <PageLoader />
  ) : (
    <>
      {data?.sections?.map((section) => (
        <Section section={section} refetch={refetch} />
      ))}
      {/* <MainHeroMember banners={sections.banners} /> */}
      {/* <FilterBar /> */}
      {/* <MainCategories categories={sections?.categories} /> */}
      {/* <FeaturedOffers offers={sections?.featuredOffers} refetch={refetch} /> */}
      {/* <NewOffers offers={sections?.newOffers} refetch={refetch} /> */}
      {/* <NearbyOffers offers={sections?.nearbyOffers} refetch={refetch} /> */}
      {/* <FeaturedBrandsHome
        title={t("Featured Brands")}
        description={t(
          "Our brands feature the best offers, discounts and deals in the market."
        )}
        brands={sections?.featuredBrands}
      /> */}
      {/* <HomeNews news={sections?.news} title={`${t("News")}`} /> */}
      {/* <FaqContent faqs={sections.faqs} /> */}
      <AppDownload />
    </>
  );
};

export default HomeMember;
